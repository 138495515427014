<template>
    <ValidationProvider :name="label"
                        :vid="name"
                        :rules="rules">
        <v-layout align-center
                    :class="className">
            <span class="d-inline-block mr-3">{{ label }}</span>
            <file-upload v-model="rawFiles"
                         :ref="'upload-' + inputId"
                         :accept="accept"
                         :inputId="inputId"
                         :multiple="multiple"
                         @input-filter="inputFilter">
                <v-btn color="primary" outlined>
                    <slot name="icon">
                        <v-icon size="20" class="mr-2">{{ icon }}</v-icon>
                    </slot>
                    {{ title }}</v-btn>
            </file-upload>
        </v-layout>
        <p class="mt-2 text--xxs"
           v-if="accept">{{ acceptedFilesNote }}</p>
        <p v-if="validationError"
           class="text--xs font-weight-medium red--text">{{ $t('validation.file_type_error') }}</p>
        <div v-if="convertedFiles.length > 0">
            <v-layout v-for="(item, i) in convertedFiles"
                      class="mt-2"
                      :key="i">
                <a @click="showDocument(item.content)"
                   target="_blank">{{ item.name }}</a>
                <v-icon size="18"
                        @click="removeFile(item)"
                        class="clickable ml-4">{{ ICONS.DELETE }}</v-icon>
            </v-layout>
        </div>
    </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { ICONS } from '@/constants/icons';
import isFieldRequired from '@/mixins/isFieldRequired';
import { convertFilesToBase64, showBase64Document, dataURLtoFile } from '@/helpers/files';

export default {
    name: 'FileInput',
    components: { ValidationProvider },
    mixins: [isFieldRequired],
    props: {
        name: {
            type: String,
            'default': ''
        },
        value: {
            type: Array,
            required: true
        },
        inputId: {
            type: String,
            required: true
        },
        label: {
            type: String,
            'default': ''
        },
        rules: {
            type: [String, Object, Array],
            'default': ''
        },
        multiple: {
            type: Boolean,
            'default': false
        },
        formVisibility: {
            type: Boolean,
            'default': false
        },
        accept: {
            type: String,
            'default': ''
        },
        className: {
            type: [String, Object, Array],
            'default': ''
        },
        buttonTitle: {
            type: String,
            'default': ''
        },
        raw: {
            type: Boolean,
            'default': false
        },
        icon: {
            type: String,
            'default': ICONS.FILE
        }
    },
    data: () => ({
        ICONS,
        convertedFiles: [],
        rawFiles: [],
        validationError: false
    }),
    computed: {
        title () {
            return this.buttonTitle || this.$t('buttons.add');
        },
        acceptedFilesNote () {
            const typesAliases = {
                xlsx: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            };

            if (this.accept) {
                const regex = new RegExp('\\w+\\/', 'gi');
                let types = this.accept.replaceAll(regex, ' .');
                if (types.includes(typesAliases.xlsx)) {
                    types = types.replace(typesAliases.xlsx, 'xlsx');
                }
                return `${this.$t('messages.accepted_files')}: ${types}`;
            } else {
                return '';
            }
        }
    },
    watch: {
        rawFiles: {
            handler (val) {
                const data = [...val];
                data.forEach(el => {
                    if (!this.accept.includes(el.type) && !this.accept.includes(el.file.type)) {
                        this.removeFile(el);
                        this.validationError = true;
                    }
                });
                if (this.raw) {
                    this.convertedFiles = data.map(el => el.file);
                } else {
                    this.convertedFiles = convertFilesToBase64(data);
                }
                this.$emit('input', this.convertedFiles);
            }
        },
        formVisibility: {
            handler (val) {
                if (val) {
                    setTimeout(() => {
                        this.value.forEach(f => {
                            this.rawFiles.push({
                                file: dataURLtoFile(f.content, f.name),
                                name: f.name
                            });
                        });
                    }, 150);
                }
            },
            immediate: true
        }
    },
    methods: {
        inputFilter (newFile) {
            this.validationError = false;
            // Create a blob field
            newFile.blob = '';
            const URL = window.URL || window.webkitURL;
            if (URL && URL.createObjectURL) {
                newFile.blob = URL.createObjectURL(newFile.file);
            }
        },
        removeFile (item) {
            if (this.multiple) {
                this.$refs[`upload-${this.inputId}`].remove(item);
            } else {
                this.$refs[`upload-${this.inputId}`].clear();
            }
        },
        showDocument (content) {
            showBase64Document(content);
        },
        clearFiles () {
            this.rawFiles = [];
            this.convertedFiles = [];
        }
    }
};
</script>

<style scoped lang="scss">
    ::v-deep {
        & .v-input__prepend-inner {
            margin-top: 6px;
            & .v-icon {
                font-size: 20px;
            }
        }
        & .v-input__append-inner {
            & .v-icon {
                font-size: 20px;
            }
        }
    }
</style>
