<template>
    <div>
        <h3 class="font-weight-medium mb-4">
            {{ $t('management.customers_and_sites.people_and_notifications.groups.notifications') }}</h3>
        <ValidationObserver ref="obs" vid="siteFormNotifications">
            <v-row align-stretch
                      :wrap="$vuetify.breakpoint.xs">
                <v-col class="pb-0">
                    <Autocomplete v-model="emailsForNotifications"
                                  name="emailsForNotifications"
                                  :label="$t('labels.notification_email')"
                                  :items="employeesWithEmail"
                                  :loading="isEmployeesWithEmailLoading"
                                  :search-input.sync="searchEmployeesWithEmail"
                                  :multiple="true"
                                  @focus="getEmployeesWithEmail(null)"
                                  @load-more-items="getEmployeesWithEmailLazyLoading(null)"></Autocomplete>
                </v-col>
                <v-col class="pb-0">
                    <Autocomplete v-model="phonesForNotifications"
                                  name="phonesForNotifications"
                                  :label="$t('labels.notification_phone')"
                                  :items="employeesWithPhone"
                                  :loading="isEmployeesWithPhoneLoading"
                                  :search-input.sync="searchEmployeesWithPhone"
                                  :multiple="true"
                                  @load-more-items="getEmployeesWithPhoneLazyLoading(null)"></Autocomplete>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="auto" class="pt-0">
                    <Checkbox className="mt-0"
                              name="informAboutAll"
                              v-model="informAboutAll"
                              :label="$t('labels.inform_all_notifications')"></Checkbox>
                </v-col>
                <v-col cols="auto" class="pt-0">
                    <Checkbox className="mt-0"
                              name="skipIncidentNotifications"
                              v-model="skipIncidentNotifications"
                              :label="$t('labels.skip_incident_notifications')"></Checkbox>
                </v-col>
            </v-row>
        </ValidationObserver>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { ValidationObserver } from 'vee-validate';
import Autocomplete from '@/components/widgets/forms/Autocomplete';
import Checkbox from '@/components/widgets/forms/Checkbox';
import debouncedEmployeesWithEmail from '@/mixins/debounced/debouncedEmployeesWithEmail';
import debouncedEmployeesWithPhone from '@/mixins/debounced/debouncedEmployeesWithPhone';

export default {
    name: 'SiteNotifications',
    mixins: [debouncedEmployeesWithEmail, debouncedEmployeesWithPhone],
    components: { ValidationObserver, Autocomplete, Checkbox },
    computed: {
        ...mapFields('manageSite', [
            'siteData.emailsForNotifications',
            'siteData.phonesForNotifications',
            'siteData.informAboutAll',
            'siteData.skipIncidentNotifications'
        ]),
        ...mapState('manageSite', [
            'siteFormVisibility'
        ]),
        ...mapGetters('employees', [
            'employeesWithEmail',
            'employeesWithPhone'
        ]),
        isFormDirty () {
            return this.$refs.obs.flags.changed;
        }
    },
    watch: {
        siteFormVisibility: {
            handler (val) {
                if (val) {
                    this.getEmployeesWithEmail(this.emailsForNotifications.length ? { 'filter[id]': this.emailsForNotifications.join(',') } : null);
                    this.getEmployeesWithPhone();
                }
            },
            immediate: true
        }
    }
};
</script>

<style scoped>

</style>
