<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <h3 class="font-weight-medium mb-4">
            {{ $t('management.customers_and_sites.people_and_notifications.groups.access') }}</h3>
        <ValidationObserver ref="obs" vid="locationAccessForm">
            <v-row>
                <v-col cols="12" class="py-0">
                    <!--TODO: create custom component for autocomplete-->
                    <v-autocomplete outlined multiple dense chips small-chips
                                    color="grey--text"
                                    :label="$t('labels.templates')"
                                    v-model="templatesForAccess"
                                    :items="filterableTemplates">
                        <template v-slot:prepend-item>
                            <v-checkbox dense class="pl-4 font-weight-medium"
                                        @change="selectAllTemplates($event)"
                                        :label="$t('labels.select_all')"></v-checkbox>
                        </template>
                        <template v-slot:selection="data">
                            <v-chip
                                v-bind="data.attrs"
                                :input-value="data.item.value"
                                close small
                                @click="data.select"
                                @click:close="templatesForAccess = templatesForAccess.filter(e => e !== data.item.value)"
                            >
                                {{ data.item.text }}
                            </v-chip>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" class="py-0">
                    <v-autocomplete outlined multiple dense chips small-chips
                                    color="grey--text"
                                    :label="$t('labels.incident_type')"
                                    v-model="incidentTypesForAccess"
                                    :items="filterableTypesList">
                        <template v-slot:prepend-item>
                            <v-checkbox dense class="pl-4 font-weight-medium"
                                        @change="selectAllIncidentTypesForAccess($event)"
                                        :label="$t('labels.select_all')"></v-checkbox>
                        </template>
                        <template v-slot:selection="data">
                            <v-chip
                                v-bind="data.attrs"
                                :input-value="data.item.value"
                                close small
                                @click="data.select"
                                @click:close="incidentTypesForAccess = incidentTypesForAccess.filter(e => e !== data.item.value)"
                            >
                                {{ data.item.text }}
                            </v-chip>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" class="py-0">
                    <Autocomplete clearable
                                  rules="required"
                                  :label="$t('labels.inspectors')"
                                  :multiple="true"
                                  :items="responsibleEmployees"
                                  :loading="isResponsibleEmployeesLoading"
                                  :search-input.sync="searchResponsibleEmployees"
                                  @focus="getResponsibleEmployees()"
                                  @click:clear="getResponsibleEmployees()"
                                  @load-more-items="getResponsibleEmployeesLazyLoading()"
                                  v-model="locationInspectors"></Autocomplete>
                </v-col>
            </v-row>
        </ValidationObserver>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapState, mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import Autocomplete from '@/components/widgets/forms/Autocomplete';
import debouncedResponsibleEmployee from '@/mixins/debounced/debouncedResponsibleEmployee';

export default {
    name: 'LocationAccess',
    components: { Autocomplete, ValidationObserver },
    mixins: [
        debouncedResponsibleEmployee
    ],
    computed: {
        ...mapFields('manageLocation', [
            'locationData.templatesForAccess',
            'locationData.incidentTypesForAccess',
            'locationData.locationInspectors'
        ]),
        ...mapGetters('templates', [
            'filterableTemplates'
        ]),
        ...mapGetters('incidents', [
            'filterableTypesList'
        ]),
        ...mapGetters('employees', [
            'responsibleEmployees'
        ]),
        ...mapState('manageLocation', [
            'locationFormVisibility'
        ]),
        isFormDirty () {
            return this.$refs.obs.flags.changed;
        }
    },
    methods: {
        selectAll (current, all) {
            return (all.length > 0) && (current.length <= all.length);
        },
        selectAllTemplates (event) {
            if (event && (this.selectAll(this.templatesForAccess, this.filterableTemplates))) {
                this.templatesForAccess = [...this.filterableTemplates.map(e => e.value)];
            } else this.templatesForAccess = [];
        },
        selectAllIncidentTypesForAccess (event) {
            if (event && (this.selectAll(this.incidentTypesForAccess, this.filterableTypesList))) {
                this.incidentTypesForAccess = [...this.filterableTypesList.map(e => e.value)];
            } else this.incidentTypesForAccess = [];
        },
        selectAllLocationInspectors (event) {
            if (event && (this.selectAll(this.locationInspectors, this.responsibleEmployees))) {
                this.locationInspectors = [...this.responsibleEmployees.map(e => e.value)];
            } else this.locationInspectors = [];
        }
    },
    watch: {
        locationFormVisibility: {
            handler (val) {
                if (val) {
                    this.$store.dispatch('templates/getTemplates', {
                        'page[number]': '-1',
                        'filter[archive]': false,
                        'filter[current]': true
                    });
                    this.$store.dispatch('incidents/getIncidentTypes', {
                        'page[number]': '-1'
                    });
                    this.$store.dispatch('employees/getResponsibleEmployees', {
                        'page[number]': '-1'
                    });
                }
            },
            immediate: true
        }
    }
};
</script>

<style scoped>

</style>
