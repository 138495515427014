<template>
    <Modal :modal-title="title"
           :dialog="siteFormVisibility"
           max-width="1600px"
           dense
           :persistent="true"
           data-test="modal"
           @keydown.enter="showConfirmation"
           @keydown.esc="closeWithConfirmation"
           @close="close"
           @visibility="visibility($event)">
        <template slot="content">
            <v-row v-if="loader">
                <v-col v-for="i in 3" :key="i" cols="12" md="4">
                    <v-skeleton-loader width="100%"
                                       height="400"
                                       type="card"
                    ></v-skeleton-loader>
                </v-col>
                <v-col cols="12">
                    <v-skeleton-loader width="100%"
                                       height="200"
                                       type="table"
                    ></v-skeleton-loader>
                </v-col>
            </v-row>
            <template v-else>
                <ManageSiteForm ref="form"></ManageSiteForm>
                <SiteNotifications class="mt-8" ref="notificationsForm"></SiteNotifications>
                <ManageLocation v-if="locationFormVisibility" data-test="ManageLocation"></ManageLocation>
                <LocationsPerSite :edit="true"
                                  v-if="editedSiteId"
                                  class="mt-4"
                                  data-test="LocationsPerSite"
                                  @switch="handleSwitch($event)"></LocationsPerSite>

                <ConfirmationDialog :dialog="showConfirmationDialog"
                                    data-test="ConfirmationDialog"
                                    @confirm="handleConfirmation($event)"
                                    :text="$t('messages.save_changes')"
                                    :action-button-title="$t('buttons.save')"></ConfirmationDialog>
                <span data-test="testKeydownSpan"></span>

                <ValidationError v-if="showError"></ValidationError>
            </template>

        </template>

        <template slot="footer">
                <v-btn color="secondary"
                       outlined
                       @click="close">{{ $t('buttons.close') }}</v-btn>
                <v-btn color="secondary"
                       :loading="loader"
                       :disabled="loader"
                       @click="save"><v-icon left>mdi-content-save</v-icon>{{ $t('buttons.save') }}</v-btn>
        </template>
    </Modal>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapState } from 'vuex';
import Modal from '@/components/templates/Modal';
import ManageLocation from '@/components/customers/manageLocation/ManageLocation';
import ManageSiteForm from '@/components/customers/manageSite/ManageSiteForm';
import LocationsPerSite from '@/components/customers/locationsPerSite/LocationsPerSite';
import SiteNotifications from '@/components/customers/manageSite/SiteNotifications';
import ConfirmationDialog from '@/components/widgets/dialogs/ConfirmationDialog';
import ValidationError from '@/components/widgets/ValidationError';

export default {
    name: 'ManageSite',
    components: { Modal, ManageSiteForm, ManageLocation, LocationsPerSite, SiteNotifications, ConfirmationDialog, ValidationError },
    data: () => ({
        selectAll: null,
        showConfirmationDialog: false,
        loader: false,
        showError: false
    }),
    computed: {
        ...mapFields('manageSite', [
            'siteFormVisibility',
            'editedSiteId'
        ]),
        ...mapState('manageCustomer', [
            'activeCustomer'
        ]),
        ...mapState('manageSite', [
            'showSiteDetails',
            'activeSite'
        ]),
        ...mapState('manageCustomer', [
            'customerFormVisibility'
        ]),
        ...mapFields('manageLocation', [
            'locationFormVisibility'
        ]),
        title () {
            return this.editedSiteId ? this.$t('site_form.edit_title') : this.$t('site_form.add_title');
        },
        isFieldsDirty () {
            const formFieldsDirty = this.$refs.form.isFormDirty || false;
            const notificationsFieldsDirty = this.$refs.notificationsForm.isFormDirty || false;

            return formFieldsDirty || notificationsFieldsDirty;
        }
    },
    methods: {
        close () {
            this.siteFormVisibility = false;
            this.showError = false;
            this.editedSiteId = null;
            this.$store.commit('manageSite/RESET_SITE_DATA');
            this.$store.commit('customers/SET_FOCUSED_FIELD', null);
            if (!this.showSiteDetails) {
                this.$store.dispatch('manageSite/cleanActiveSiteInformation');
            }
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            this.$emit('close-manage');
        },
        closeWithConfirmation () {
            if (this.isFieldsDirty) {
                this.showConfirmationDialog = true;
            } else {
                this.close();
            }
        },
        visibility (event) {
            this.siteFormVisibility = event;
        },
        handleSwitch (val) {
            if (val === 'tasks') {
                this.$router.push({ path: this.planningPath });
                this.close();
            }
        },
        getSitesPerCustomer () {
            return this.$store.dispatch('customers/getSitesPerCustomer', this.activeCustomer.id);
        },
        getActiveCustomer () {
            return this.$store.dispatch('manageCustomer/getActiveCustomer', this.activeCustomer.id);
        },
        updateCustomerInformation () {
            return Promise.all([this.getSitesPerCustomer(), this.getActiveCustomer()]);
        },
        updateSite () {
            this.loader = true;
            this.$refs.form.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        return this.$store.dispatch('manageSite/updateSite')
                            .then(() => {
                                this.updateCustomerInformation()
                                    .then(() => {
                                        this.close();
                                    });
                            })
                            .finally(() => {
                                this.loader = false;
                            });
                    } else {
                        this.loader = false;
                    }
                });
        },
        update () {
            this.loader = true;
            this.$refs.form.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        return this.$store.dispatch('manageSite/update')
                            .then(() => {
                                this.updateCustomerInformation();
                                this.$store.dispatch('customers/getLocationsPerSite', this.editedSiteId);
                            })
                            .finally(() => {
                                this.loader = false;
                            });
                    } else {
                        this.loader = false;
                    }
                });
        },
        createSite () {
            this.loader = true;

            this.$refs.form.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        return this.$store.dispatch('manageSite/createSite')
                            .then(() => {
                                this.updateCustomerInformation()
                                    .then(() => {
                                        this.close();
                                    });
                            })
                            .finally(() => {
                                this.loader = false;
                            });
                    } else {
                        this.loader = false;
                    }
                });
        },
        create () {
            this.loader = true;

            this.$refs.form.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        return this.$store.dispatch('manageSite/create')
                            .then(() => {
                                this.getSitesPerCustomer();
                            })
                            .finally(() => {
                                this.loader = false;
                            });
                    } else {
                        this.loader = false;
                    }
                });
        },
        filledForm () {
            this.loader = true;

            this.$refs.form.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        this.siteFormVisibility = false;
                        this.$store.dispatch('manageSite/setNestedSites');
                        this.$store.commit('manageSite/SET_IS_FORM_FILLED', true);
                        this.$store.commit('manageSite/RESET_SITE_DATA');
                        this.$store.commit('manageLocation/RESET_NESTED_LOCATIONS');
                        this.loader = false;
                    } else {
                        this.loader = false;
                    }
                });
        },
        save () {
            if (this.editedSiteId) {
                this.update();
            } else {
                this.create();
            }
        },
        showConfirmation () {
            if (this.isFieldsDirty) {
                this.showConfirmationDialog = true;
            }
        },
        handleConfirmation (event) {
            if (event) {
                this.save();
                this.showConfirmationDialog = false;
            } else {
                this.showConfirmationDialog = false;
            }
        }
    }
};
</script>

<style scoped lang="scss">
</style>
