<template>
    <GetFile :title="$t('labels.download')"
                        :loader="attachmentLoader"
                        @get-file="getAttachment"></GetFile>
</template>

<script>
import GetFile from '@/components/widgets/buttons/GetFile';

export default {
    name: 'AttachmentDownload',
    components: { GetFile },
    props: {
        currentFile: {
            type: String,
            'default': ''
        }
    },
    data: () => ({
        attachmentLoader: false
    }),
    methods: {
        getAttachment () {
            this.attachmentLoader = true;

            return this.$store.dispatch('commonActions/downloadAttachment', {
                url: this.currentFile
            })
                .finally(() => {
                    this.attachmentLoader = false;
                });
        }
    }
};
</script>
